import { Button, ButtonProps, Divider, Stack } from '@mui/material'
import { memo, useMemo } from 'react'

import { SocialLoginProvider, SocialLoginButtonConfig } from 'src/types/SocialLoginType'

import GoogleLogo from 'src/assets/svg/google.svg'
import MicrosoftLogo from 'src/assets/svg/microsoft.svg'

export type SocialLoginProps = {
  onLogin: (type: SocialLoginProvider) => Promise<void>
  loading?: boolean
  separatorPosition?: 'top' | 'bottom'
}
const IconStyles = {
  height: '1.2rem',
  width: '1.2rem',
}
const CommonButtonProps: ButtonProps = {
  variant: 'outlined',
  type: 'button',
  fullWidth: false,
}
export default memo(function SocialLogin({ onLogin, loading, separatorPosition }: SocialLoginProps) {
  const SocialLoginButtons: SocialLoginButtonConfig[] = useMemo(() => {
    const styles = { ...IconStyles, opacity: loading ? 0.5 : 1 }
    return [
      {
        id: 'google',
        label: 'Continue with Google',
        icon: <GoogleLogo style={styles} />,
        enable: process.env.NEXT_PUBLIC_ENABLE_GOOGLE_SIGNIN === 'true',
      },
      {
        id: 'microsoft',
        label: 'Continue with Microsoft',
        icon: <MicrosoftLogo style={styles} />,
        enable: process.env.NEXT_PUBLIC_ENABLE_MS_SIGNIN === 'true',
      },
    ]
  }, [loading])

  const isAnyEnabled = SocialLoginButtons.some(({ enable }) => enable)

  return isAnyEnabled ? (
    <Stack gap={2}>
      {separatorPosition === 'top' ? (
        <Divider textAlign="center" sx={{ color: 'GrayText', fontSize: '85%' }}>
          or
        </Divider>
      ) : (
        false
      )}
      <Stack gap={2}>
        {SocialLoginButtons.map(({ id, label, icon, enable }) =>
          enable ? (
            <Button key={id} disabled={loading} onClick={() => onLogin(id)} startIcon={icon} {...CommonButtonProps}>
              {label}
            </Button>
          ) : (
            false
          )
        )}
      </Stack>
      {separatorPosition === 'bottom' ? (
        <Divider textAlign="center" sx={{ color: 'GrayText', fontSize: '85%' }}>
          or
        </Divider>
      ) : (
        false
      )}
    </Stack>
  ) : null
})

